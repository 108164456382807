//MEDIA: 1449
@media screen and (max-width: 1449px) {
  .footer__inner-map {
    max-width: 600px;
  }
  .billboard__inner-info {
    padding-left: 60px;
  }
  .vacansy__items {
    flex-wrap: wrap;
  }
  .vacansy__items-item {
    max-width: 100%;
    margin-bottom: 30px;
  }
  .catalog__inner {
    flex-wrap: wrap;
  }
  .catalog__inner-filters {
    max-width: 100%;
    margin-right: 0;
    order: 1;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
  }
  .catalog__filters-line + .catalog__filters-line {
    margin-top: 0;
  }
  .catalog__filters-line {
    width: 100%;
    margin: 0 15px 30px 15px;
    max-width: calc(25% - 30px);
  }
}
//MEDIA: 1449

//MEDIA: 1249
@media screen and (max-width: 1249px) {
  .header__burger {
    margin-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 26px;
    margin-right: 20px;
    cursor: pointer;
    z-index: 100;
    span {
      height: 4px;
      width: 100%;
      background: #818181;
      border-radius: 3px;
      transition: all 0.3s;
    }
    &::before {
      content: "";
      position: absolute;
      height: 4px;
      width: 100%;
      background: #818181;
      border-radius: 3px;
      left: 0;
      top: 0;
      transition: all 0.3s;
    }
    &::after {
      content: "";
      position: absolute;
      height: 4px;
      width: 100%;
      background: #818181;
      border-radius: 3px;
      left: 0;
      bottom: 0;
      transition: all 0.3s;
    }
    &.active {
      span {
        opacity: 0;
        visibility: hidden;
      }
      &::before {
        transform: rotate(45deg);
        top: 11px;
      }
      &::after {
        transform: rotate(-45deg);
        bottom: 10px;
      }
    }
  }
  .header__nav {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-200%);
    width: 50%;
    height: 100%;
    background: #fff;
    padding: 35px;
    top: 0;
    left: 0;
    margin-left: 0;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
    z-index: 99;
    transition: all 0.3s;
    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }
  .header__nav-list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .header__nav-item {
    margin: 15px;
    width: 100%;
    text-align: center;
  }
  .header__nav-link {
    text-align: center;
    width: 100%;
    display: block;
  }
  .header__inner {
    justify-content: flex-start;
  }
  .header__buttons {
    display: none;
  }
  .header__btn--mobile {
    display: flex;
    min-width: 165px;
    text-align: center;
    justify-content: center;
  }
  .header__logo {
    margin-right: 20px;
  }
  .header__btn--mobile {
    margin: 0 10px 0;
    padding: 11px 18px;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    &.header__btn--mobile--1 {
      margin-left: auto;
    }
  }
  .header {
    .container {
      padding: 0 10px;
    }
  }
  .header__inner {
    flex-wrap: wrap;
    padding: 15px 0;
  }
  .header__btn--mobile {
    margin-top: 14px;
  }
}

//!MEDIA: 1249

//MEDIA: 1199
@media screen and (max-width: 1199px) {
  .footer__inner-nav {
    display: none;
  }
  .billboard__inner {
    flex-wrap: wrap;
  }
  .billboard__inner-info {
    order: 1;
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
    max-width: 100vw;
  }
  .billboard__inner-imagebox {
    margin-left: auto;
    margin-right: auto;
    position: initial;
    margin-left: -15px;
    margin-right: -15px;
    max-width: 100vw;
    width: calc(100vw - 10px);
  }
  .billboard__imagebox-textbox {
    left: 0;
    width: 100%;
    max-width: 100%;
    justify-content: center;
  }
  .question__inner {
    flex-wrap: wrap;
    padding: 35px;
  }
  .question__inner-form {
    max-width: 100%;
    margin-bottom: 30px;
    width: 100%;
  }
  .question__inner-info {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
  }
  .question__info-text {
    margin-left: 0;
    max-width: 100%;
    text-align: left;
  }
  .question__info-links {
    justify-content: flex-start;
  }
  .question__form-inputbox {
    max-width: 600px;
  }
  .catalog__content-types {
    flex-wrap: wrap;
  }
  .catalog__types-type {
    width: calc(33.333% - 22px);
    margin-bottom: 22px;
  }
  .vacansy-page__request-infobox {
    flex-wrap: wrap;
    padding-bottom: 5px;
  }
  .vacansy-page__request-col {
    margin-bottom: 30px;
  }
}

//!MEDIA: 1199

//MEDIA: 991
@media screen and (max-width: 991px) {
  .footer__inner {
    flex-wrap: wrap;
  }
  .footer__inner-info {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    width: 100%;
  }
  .footer__inner-map {
    margin-right: 0;
    max-width: 100%;
    height: 250px;
  }
  .comfort__items {
    flex-wrap: wrap;
  }
  .comfort__items-item {
    max-width: calc(50% - 20px);
    margin-bottom: 20px;
  }
  .comfort__contents {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 20px);
  }
  .comfort__contents-content::before {
    left: -10px;
    bottom: -10px;
  }
  .comfort__contents-content {
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
  }
  .sphere__items-item {
    max-width: calc(50% - 30px);
  }
  .catalog__filters-line {
    max-width: calc(50% - 30px);
  }
  .vacansy-page__info-box {
    margin-top: 0;
    margin-right: 0;
  }
  .vacansy-page__inner-info {
    display: block;
    margin-bottom: 30px;
  }
  .vacansy-page__info-title {
    margin-bottom: 30px;
  }
  .vacansy-page__info-box {
    margin-left: -45px;
    margin-right: -45px;
    max-width: 100vw;
  }
  .vacansy-page__request-title {
    margin-left: 45px;
  }
  .vacansy-page__inner {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .vacansy-page__inner-request {
    margin-left: -45px;
    margin-right: -45px;
    max-width: 100vw;
    width: 100vw;
  }
  .vacansy-page__btn {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

//!MEDIA: 991

//MEDIA: 767
@media screen and (max-width: 767px) {
  .billboard__imagebox-mainimage {
    margin-left: auto;
    margin-right: auto;
  }
  .vacansy__item-bot {
    flex-wrap: wrap;
  }
  .vacansy__bot-info {
    margin-right: 0;
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
  }
  .vacansy__links-btn {
    margin-left: 0;
    margin-right: 15px;
  }
}

//!MEDIA: 767

//MEDIA: 649
@media screen and (max-width: 649px) {
  .header__logo-image {
    height: 48px;
    width: 48px;
  }
  .header__nav {
    width: 100%;
  }
  .footer__logo {
    float: left;
    margin-right: 34px;
  }
  .footer__logo-image {
    height: 48px;
    width: 48px;
  }
  .billboard__inner-imagebox {
    border-radius: 0 23px 23px 0;
  }
  .billboard__inner-imagebox::before {
    top: -10px;
    width: 100%;
    right: 0px;
    height: 245px;
    background-size: contain;
  }
  .billboard__imagebox-triangles {
    width: 59px;
    height: 59px;
  }
  .billboard__imagebox-mainimage {
    height: 258px;
    max-width: 228px;
    margin-top: -30px;
  }
  .billboard {
    margin-top: 50px;
    margin-bottom: 105px;
  }
  .billboard__inner-info {
    margin-top: 10px;
    padding: 40px 20px 100px;
  }
  .billboard__info-points {
    height: 7px;
    margin-bottom: 15px;
  }
  .billboard__info-title {
    font-size: 28px;
    line-height: 100%;
    margin-bottom: 20px;
  }
  .billboard__textbox-text {
    padding: 15px;
    margin: 0 7px;
    font-size: 15px;
    line-height: 120%;
  }
  .billboard__textbox-text span {
    font-size: 24px;
    line-height: 100%;
  }
  .billboard__info-btn {
    padding: 15px 20px;
    width: 100%;
  }
  .comfort {
    margin: 105px 0 50px;
  }
  .section__title {
    font-size: 24px;
    line-height: 100%;
    border-bottom: 3px solid #818181;
  }
  .comfort__subtitle {
    font-size: 15px;
    line-height: 120%;
  }
  .comfort__items {
    margin-top: 35px;
    margin-right: -10px;
    margin-left: -10px;
  }
  .comfort__items-item {
    padding: 15px;
    max-width: 50%;
    &.active {
      border: 3px solid #818181;
      padding: 15px;
    }
  }
  .comfort__item-text {
    margin-top: 10px;
    font-size: 14px;
    line-height: 120%;
  }
  .comfort__item-number {
    font-size: 25px;
    line-height: 100%;
    padding: 7px 13px;
  }
  .comfort__contents-content.active {
    padding: 35px 15px 20px 15px;
  }
  .comfort__content-text {
    font-size: 14px;
    line-height: 120%;
  }
  .comfort__contents-content::before {
    height: 124px;
    max-width: 296px;
    background-size: contain;
  }
  .comfort__content-slider {
    .slick-dots {
      top: -40px;
    }
    .slick-dots li {
      margin: 0 1px;
    }
    .slick-dots li button:before {
      width: 7px;
      height: 7px;
    }
  }
  .comfort__contents-content::before {
    bottom: -25px;
  }
  .comfort__contents-content {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }
  .sphere {
    margin: 50px 0;
  }
  .sphere__title {
    margin-bottom: 38px;
  }
  .sphere__item-image {
    height: 220px;
  }
  .sphere__item-text {
    font-size: 16px;
    line-height: 120%;
    padding: 6px 10px 12px;
  }
  .sphere__items-item {
    max-width: calc(50% - 14px);
    margin: 0 7px 14px;
  }
  .sphere__items {
    margin-left: -7px;
    margin-right: -7px;
  }
  .sphere__wrapper-bg {
    height: 470px;
  }
  .vacansy {
    margin: 50px 0;
  }
  .vacansy__title {
    margin-bottom: 35px;
  }
  .footer {
    margin-top: 50px;
  }
  .vacansy__items-item {
    border: 3px solid #fff;
    padding: 15px;
  }
  .vacansy__top-title {
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 5px;
  }
  .vacansy__top-id {
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy__top-price {
    font-size: 16px;
    line-height: 110%;
  }
  .vacansy__item-bot {
    margin-top: 38px;
  }
  .vacansy__info-line + .vacansy__info-line {
    margin-top: 5px;
  }
  .vacansy__line-icon {
    height: 14px;
    width: 14px;
    margin-right: 5px;
  }
  .vacansy__line-text {
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy__links-btn {
    font-size: 10px;
    line-height: 100%;
  }
  .vacansy__links-btn.btn {
    font-size: 10px;
    line-height: 100%;
    padding: 6px 10px 7px;
  }
  .vacansy__bot-links {
    width: 100%;
    justify-content: space-between;
  }
  .vacansy__bot-info {
    margin-bottom: 15px;
  }
  .question__inner {
    padding: 30px 17px;
    margin-right: -15px;
    margin-left: 5px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .question__inner::before {
    left: -13px;
    top: -20px;
    height: calc(100% + 40px);
    max-width: 323px;
    width: 100%;
    background-image: url(../img/question/layout--mobile.png);
    background-size: contain;
  }
  .question__form-title {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 100%;
  }
  .question__form-text {
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 15px;
  }
  .question__form-inputbox {
    height: 35px;
  }
  .question__form-input {
    padding: 8px 25px;
    font-size: 16px;
    line-height: 120%;
    opacity: 0.4;
  }
  .question__form-btn {
    font-size: 16px;
    line-height: 100%;
  }
  .question__info-title {
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 10px;
  }
  .question__info-text {
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 15px;
  }
  .question__links-btn {
    font-size: 16px;
    line-height: 100%;
    margin: 0 15px 0 0;
  }
  .question {
    margin: 60px 0 0 0;
  }
  .about__inner {
    padding: 15px;
  }
  .about__inner-title {
    margin-bottom: 35px;
  }
  .about__inner-descr {
    font-size: 16px;
    line-height: 120%;
  }
  .catalog__filters-line {
    max-width: calc(100%);
    margin: 0 0 20px 0;
  }
  .catalog__inner-filters {
    padding: 15px;
  }
  .catalog__line-title {
    font-size: 16px;
  }
  .catalog__line-text {
    padding: 6px 0;
    font-size: 16px;
  }
  .catalog__content-pagination {
    justify-content: center;
  }
  .catalog__pagination-item {
    height: 20px;
    min-width: 20px;
    font-size: 14px;
  }
  .catalog__types-type {
    padding: 15px 2px;
    font-size: 14px;
    line-height: 100%;
    margin: 0 5px 10px 5px;
    width: calc(33.333% - 10px);
  }
  .contacts {
    margin: 20px 0 0;
  }
  .contacts__inner {
    overflow: visible;
    height: 668px;
    iframe {
      border-radius: 25px;
    }
  }
  .contacts__inner-info {
    padding: 20px;
    border: 3px solid #ffffff;
    top: -5px;
    left: -10px;
    width: calc(100vw - 20px);
  }
  .contacts__info-title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 100%;
  }
  .contacts__info-line {
    font-size: 14px;
    line-height: 110%;
    align-items: flex-start;
    span {
      font-size: 14px;
      line-height: 110%;
    }
  }
  .body--contacts {
    .footer__inner-map {
      display: none;
    }
    .footer {
      padding-bottom: 10px;
    }
  }
  .news-page__inner {
    padding: 15px;
  }
  .news-page__inner-title {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .news-page__inner-descr {
    margin-top: 15px;
  }
  .news-page__descr-title {
    font-size: 15px;
  }
  .news-page__descr-text {
    font-size: 15px;
  }
  .vacansy-page__inner {
    padding: 20px;
  }
  .vacansy-page__inner-info {
    margin-bottom: 20px;
  }
  .vacansy-page__info-title {
    font-size: 16px;
    line-height: 120%;
    max-width: 175px;
    margin-bottom: 20px;
  }
  .vacansy-page__info-title span {
    margin-top: 5px;
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy-page__info-box {
    padding: 25px 30px;
    margin-left: -25px;
    width: calc(100vw - 20px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 3px solid #ffffff;
  }
  .vacansy-page__info-price {
    font-size: 16px;
    line-height: 120%;
    span {
      display: block;
    }
  }
  .vacansy-page__info-linesbox {
    margin-top: 0;
    order: -1;
  }
  .vacansy-page__info-line {
  }
  .vacansy-page__line-icon {
    margin-right: 3px;
    width: 14px;
    height: 14px;
  }
  .vacansy-page__line-text {
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy-page__descr-title {
    font-size: 14px;
    line-height: 110%;
    margin-bottom: 20px;
  }
  .vacansy-page__descr-text {
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy-page__inner-request {
    width: calc(100vw - 20px);
    max-width: calc(100vw - 20px);
    margin-right: -25px;
    margin-left: -25px;
  }
  .vacansy-page__request-title {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 110%;
    margin-left: 25px;
  }
  .vacansy-page__request-infobox {
    padding: 15px;
    border: 3px solid #818181;
  }
  .vacansy-page__btn {
    width: 100%;
  }
  .vacansy-page__col-title {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy-page__col-text {
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy-page__request-col {
    max-width: calc(50% - 20px);
    margin: 10px;
    width: 100%;
  }
  .vacansy-page__inner-descr {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: calc(100vw - 40px);
      height: calc(100% + 250px);
      background-color: rgb(255, 255, 255);
      z-index: -1;
      top: -30px;
      left: -15px;
    }
  }
}

//!MEDIA: 649

//MEDIA: 449
@media screen and (max-width: 449px) {
  .header__btn--mobile--3 {
    margin-left: auto;
  }
  .sphere__item-image {
    height: 170px;
  }
}
//MEDIA: 449

//!MEDIA: 389
@media screen and (max-width: 389px) {
  .footer__logo-image {
    height: 38px;
    width: 38px;
  }
  .footer__logo {
    margin-right: 15px;
  }
  .footer__info-line {
    font-size: 14px;
    align-items: flex-start;
  }
  .header__btn--mobile {
    min-width: auto;
  }
  .sphere__item-image {
    height: 132px;
  }
  .catalog__types-type {
    width: calc(50% - 10px);
  }
  .contacts__inner-info {
    padding: 15px;
  }
  .contacts__info-line {
    display: block;
  }
  .contacts__info-line span {
    margin-top: 5px;
    display: block;
    margin-left: 0;
  }
}

//!MEDIA: 389

//MEDIA: 349
@media screen and (max-width: 349px) {
  .header__btn--mobile {
    padding: 8px 10px;
  }
}

//!MEDIA: 349
