@import "mixins";
@import "vars";
@import "fonts";
@import "local-fonts";
@import "global";
@import "svg";
@import "bem-blocks";

//start header
.header {
  position: relative;
}
.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}
.header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__logo-image {
  max-width: 100%;
  height: 58px;
  width: 58px;
}
.header__nav {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.header__nav-list {
  display: flex;
  align-items: center;
}
.header__nav-item {
  padding: 0 15px;
  margin: 0 15px;
}
.header__nav-link {
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #818181;
  transition: all 0.3s;
  &:hover {
    opacity: 0.5;
  }
}
.header__buttons {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.header__btn {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #818181;
  padding: 15px 40px;
  margin: 0 17px;
  &:hover {
    background-color: #818181;
    color: #fff;
  }
}
.header__btn--mobile {
  display: none;
}
.header__burger {
  display: none;
}
//end header

//start footer
.footer {
  padding: 35px 0 45px;
  background: #818181;
  margin-top: 140px;
}
.footer__inner {
  display: flex;
  justify-content: space-between;
}
.footer__inner-info {
  max-width: 290px;
  margin-right: 15px;
}
.footer__logo {
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
.footer__logo-image {
  height: 58px;
  width: 58px;
  max-width: 100%;
}
.footer__info-line {
  font-size: 16px;
  line-height: 110%;
  color: #ffffff;
  font-weight: 400;
  display: flex;
  align-items: center;
  span {
    font-weight: 500;
    margin-left: 5px;
  }
}
.footer__inner-map {
  margin-right: 15px;
  height: 150px;
  max-width: 930px;
  width: 100%;
  iframe {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}
.footer__inner-nav {
  max-width: 110px;
}
.footer__nav-list {
  list-style: none;
}
.footer__nav-item {
  text-align: right;
  & + & {
    margin-top: 20px;
  }
}
.footer__nav-link {
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  transition: all 0.3s;
  &:hover {
    opacity: 0.7;
  }
}
//end footer

//start billboard
.billboard {
  position: relative;
  margin: 75px 0 210px;
}
.billboard__inner {
  width: 100%;
  max-width: 1770px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}
.billboard__inner-info {
  max-width: 855px;
  padding: 100px 70px 100px 160px;
  background-color: #fff;
  border-radius: 50px 0px 0px 50px;
}
.billboard__info-points {
  max-width: 100%;
  height: 10px;
  margin-bottom: 40px;
}
.billboard__info-title {
  font-family: "Roboto Slab", serif;
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 47px;
  line-height: 100%;
  color: #818181;
}
.billboard__info-btn {
  font-weight: bold;
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  color: #ffffff;
  background: #818181;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding: 15px 63px;
  width: fit-content;
  &:hover {
    opacity: 0.7;
  }
}
.billboard__inner-imagebox {
  background: #ebebeb;
  max-width: 885px;
  width: 100%;
  position: relative;
  border-radius: 0 50px 50px 0px;
  display: flex;
  flex-direction: column;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    right: -20px;
    top: -20px;
    height: calc(100% + 40px);
    width: 832px;
    background-image: url(../img/billboard/layout.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }
}
.billboard__imagebox-triangles {
  height: 136px;
  width: 136px;
  max-width: 100%;
  top: 30px;
  left: 30px;
  position: absolute;
}
.billboard__imagebox-mainimage {
  height: 552px;
  max-width: 488px;
  margin-top: -70px;
  margin-left: 205px;
}
.billboard__imagebox-textbox {
  position: absolute;
  bottom: -70px;
  left: 15px;
  display: flex;
}
.billboard__textbox-text {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 30px 125px 30px 35px;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #818181;
  margin: 0 15px;
  width: 100%;
  max-width: 332px;
  span {
    font-family: "Roboto Slab", serif;
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 30px;
    line-height: 100%;
    color: #818181;
  }
}
//end billboard

//start comfort
.comfort {
  margin: 100px 0;
}
.comfort__title {
  margin-bottom: 10px;
}
.comfort__subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #818181;
}
.comfort__items {
  display: flex;
  justify-content: space-between;
  margin-top: 75px;
}
.comfort__items-item {
  width: 100%;
  max-width: calc(25% - 20px);
  border: 5px solid transparent;
  border-radius: 25px;
  padding: 60px 35px 0px;
  cursor: pointer;
  &.active {
    background: #ffffff;
    border: 5px solid #818181;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 35px;
    &:hover {
      opacity: 1;
    }
  }
}
.comfort__item-number {
  font-family: "Roboto Slab", serif;
  background: rgba(#ebebeb, 0.3);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 50px;
  line-height: 100%;
  color: #818181;
  padding: 10px 23px;
  width: fit-content;
}
.comfort__item-text {
  margin-top: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}
.comfort__contents {
  position: relative;
}
.comfort__contents-content {
  width: 100%;
  max-width: 936px;
  background: linear-gradient(180deg, #f9f9f9 0%, #ffffff 100%);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  opacity: 0;
  visibility: hidden;
  height: 0;
  padding: 0;
  &::before {
    content: "";
    position: absolute;
    left: -20px;
    bottom: -20px;
    width: 100%;
    height: 235px;
    width: 745px;
    background-image: url(../img/comfort/layout.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    height: auto;
    padding: 78px 120px 70px;
  }
}
.comfort__content-slider {
  width: 100%;
  .slick-dots {
    bottom: auto;
    top: -40px;
    text-align: left;
  }
  .slick-dots li {
    margin: 0 2.5px;
  }
  .slick-dots li button:before {
    width: 10px;
    height: 10px;
    background: #ebebeb;
    content: "";
    border-radius: 50%;
    opacity: 1;
  }
  .slick-dots .slick-active button:before {
    background: #6d6d6d;
  }
}
.comfort__content-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}
//end comfort

//start sphere
.sphere {
  position: relative;
  margin: 100px 0 140px;
}
.sphere__title {
  margin-bottom: 75px;
}
.sphere__wrapper {
  position: relative;
}
.sphere__wrapper-bg {
  position: absolute;
  width: 100vw;
  height: 689px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: -1;
}
.sphere__inner {
  margin-left: auto;
  margin-right: auto;
}
.sphere__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.sphere__items-item {
  width: 100%;
  max-width: calc(33.333% - 30px);
  margin: 0 15px 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  border: 3px solid #ffffff;
}
.sphere__item-image {
  width: 100%;
  max-width: 100%;
  height: 395px;
  border-radius: 25px 25px 0px 0px;
}
.sphere__item-text {
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  text-align: center;
  color: #818181;
  background-color: #fff;
  padding: 17px 30px 30px;
  width: 100%;

  border-radius: 0px 0px 25px 25px;
}
//end sphere

//start vacansy
.vacansy {
  position: relative;
  margin: 140px 0;
}
.vacansy__title {
  margin-bottom: 75px;
}
.vacansy__items {
  display: flex;
  justify-content: space-between;
}
.vacansy__items-item {
  width: 100%;
  max-width: calc(50% - 30px);
  border: 5px solid #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.vacansy__item-top {
  display: flex;
  justify-content: space-between;
}
.vacansy__top-box {
  max-width: 60%;
}
.vacansy__top-title {
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  margin-bottom: 25px;
}
.vacansy__top-id {
  font-size: 20px;
  line-height: 120%;
  color: #818181;
  opacity: 0.4;
}
.vacansy__top-price {
  font-family: "Roboto Slab", serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 110%;
  text-align: right;
  color: #818181;
  span {
    font-weight: normal;
    color: #818181;
    display: block;
  }
}
.vacansy__item-bot {
  margin-top: 75px;
  display: flex;
  justify-content: space-between;
}
.vacansy__bot-info {
  margin-right: 20px;
}
.vacansy__info-line {
  display: flex;
  align-items: center;
  & + & {
    margin-top: 15px;
  }
}
.vacansy__line-icon {
  margin-right: 10px;
  height: 20px;
  width: 20px;
  max-width: 100%;
  display: block;
}
.vacansy__line-text {
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}
.vacansy__bot-links {
  display: flex;
  align-items: center;
}
.vacansy__links-btn {
  font-family: "Roboto Slab", serif;
  margin-left: 25px;
  transition: all 0.3s;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  color: #818181;
  &:hover {
    opacity: 0.7;
  }
  &.btn {
    font-family: "Roboto Slab", serif;
    background: #818181;
    border: 3px solid #ffffff;
    box-shadow: 0px 4px 24px rgba(41, 215, 121, 0.1);
    border-radius: 25px;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
    padding: 10px 18px 9px;
    &:hover {
      opacity: 0.7;
    }
  }
}
//end vacansy

//start question
.question {
  margin: 160px 0 20px;
}
.question__inner {
  display: flex;
  justify-content: space-between;
  position: relative;
  background: #ffffff;
  border: 5px solid #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px 155px 45px 35px;
  min-height: 248px;
  &::before {
    content: "";
    position: absolute;
    left: -20px;
    top: -20px;
    height: calc(100% + 40px);
    width: 715px;
    background-image: url(../img/question/layout.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }
}
.question__inner-form {
  width: 100%;
  max-width: 453px;
}
.question__form-title {
  font-family: "Roboto Slab", serif;
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  padding-bottom: 5px;
  border-bottom: 3px solid #818181;
  width: fit-content;
}
.question__form-text {
  font-size: 20px;
  line-height: 120%;
  color: #818181;
  margin-bottom: 25px;
}
.question__form-inputbox {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  height: 42px;
  background: #ffffff;
  border: 3px solid #818181;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.question__form-input {
  width: 100%;
  height: 100%;
  padding: 8px 25px 8px 25px;
  font-size: 16px;
  line-height: 120%;
  border-radius: 25px;
  outline: transparent;
  border: none;
  color: #818181;
  &:placeholder {
    font-size: 16px;
    line-height: 120%;
    color: #818181;
  }
}
.question__form-btn {
  background: #818181;
  border-radius: 25px;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  font-family: "Roboto Slab", serif;
  height: 100%;
  padding: 10px 20px 9px;
  transition: all 0.3s;
  border: none;
  border-radius: 25px;
  margin-right: -1px;
  &:hover {
    opacity: 0.8;
  }
}
.question__inner-info {
  margin-left: auto;
}
.question__info-title {
  font-family: "Roboto Slab", serif;
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  padding-bottom: 5px;
  border-bottom: 3px solid #818181;
  width: fit-content;
}
.question__info-text {
  font-size: 20px;
  line-height: 120%;
  color: #818181;
  margin-bottom: 25px;
  text-align: center;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}
.question__info-links {
  display: flex;
  justify-content: center;
}
.question__links-btn {
  padding: 8px 20px 9px;
  cursor: pointer;
  margin: 0 7px;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  font-family: "Roboto Slab", serif;
  color: #ffffff;
  border-radius: 25px;
  border: 3px solid #ffffff;
  &:hover {
    opacity: 0.7;
  }
  img {
    max-width: 100%;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  &.viber {
    background: linear-gradient(232.11deg, #59267c 0%, #8f5db7 100%);
    box-shadow: 0px 4px 24px rgba(41, 215, 121, 0.1);
  }
  &.telegram {
    background: #0088cc;
    box-shadow: 0px 4px 24px rgba(41, 215, 121, 0.1);
  }
}
//end question

//start catalog
.catalog {
  margin: 25px 0 0;
  .vacansy__items-item {
    max-width: 100%;
    margin-bottom: 30px;
  }
  .vacansy__top-title {
    max-width: 410px;
  }
}
.catalog__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.catalog__inner-filters {
  width: 100%;
  max-width: 333px;
  margin-right: 30px;
  background: #ffffff;
  border-radius: 25px;
  padding: 25px;
}
.catalog__filters-line {
  & + & {
    margin-top: 45px;
  }
}
.catalog__line-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  color: #818181;
  font-family: "Roboto Slab", serif;
  margin-bottom: 12px;
}
.catalog__line-text {
  font-size: 20px;
  line-height: 100%;
  color: #343131;
  cursor: pointer;
  border-top: 1px solid #acacac;
  padding: 12px 0;
  transition: all 0.3s;
  position: relative;
  display: flex;
  align-items: center;
  &.active {
    color: #818181;
    font-weight: 500;
    padding-left: 15px;
    &::before {
      opacity: 1;
      visibility: visible;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #818181;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
  }
}
.catalog__inner-content {
  width: 100%;
}
.catalog__content-types {
  display: flex;
  align-items: center;
  margin-left: -11px;
  margin-right: -11px;
}
.catalog__types-type {
  padding: 15px 25px;
  width: calc(16.6666 - 22px);
  margin: 0 11px;
  background: red;
  display: flex;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 25px;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #818181;
  transition: all 0.3s;
  &:hover {
    background-color: #818181;
    color: #fff;
  }
}
.catalog__content-items {
  margin-top: 18px;
}
.catalog__content-pagination {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.catalog__pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  margin: 0 10px;
  cursor: pointer;
  color: #818181;
  &.active {
    color: #ffffff;
    background: #ebebeb;
    cursor: initial;
  }
}
//end catalog

//start vacansy-page
.vacansy-page {
  margin-top: 25px;
}
.vacansy-page__inner {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  border: 5px solid #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
}
.vacansy-page__inner-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}
.vacansy-page__info-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  font-family: "Roboto Slab", serif;
  max-width: 418px;
  span {
    font-weight: normal;
    font-size: 20px;
    line-height: 120%;
    color: #818181;
    opacity: 0.8;
    font-family: "Roboto", sans-serif;
    display: block;
    margin-top: 15px;
  }
}
.vacansy-page__info-box {
  background: #ebebeb;
  border: 5px solid #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 30px;
  margin-left: auto;
  margin-right: -35px;
  margin-top: -35px;
}
.vacansy-page__info-price {
  font-family: "Roboto Slab", serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  span {
    font-weight: 400;
  }
}
.vacansy-page__info-linesbox {
  margin-top: 30px;
}
.vacansy-page__info-line {
  display: flex;
  align-content: center;
  & + & {
    margin-top: 5px;
  }
}
.vacansy-page__line-icon {
  max-width: 100%;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.vacansy-page__line-text {
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}
.vacansy-page__inner-descr {
  width: 100%;
}
.vacansy-page__descr-title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  color: #818181;
  font-family: "Roboto Slab", serif;
}
.vacansy-page__descr-text {
  max-width: 1020px;
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}
.vacansy-page__inner-request {
  margin-top: 30px;
  width: 100%;
}
.vacansy-page__request-title {
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  color: #818181;
  margin-bottom: 25px;
}
.vacansy-page__request-infobox {
  background: #ffffff;
  border: 4px solid #818181;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.vacansy-page__request-col {
  margin: 0 15px;
  max-width: 193px;
}
.vacansy-page__col-title {
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 120%;
  color: #818181;
  opacity: 0.4;
}
.vacansy-page__col-text {
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}
.vacansy-page__btn {
  display: block;
  margin-top: 25px;
  background: #818181;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 24px rgba(41, 215, 121, 0.1);
  border-radius: 25px;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Roboto Slab", serif;
  padding: 15px 63px;
  width: fit-content;
  margin-left: auto;
  &:hover {
    opacity: 0.7;
  }
}
//end vacansy-page

//start contacts
.contacts {
  margin-top: 25px;
}
.contacts__inner {
  position: relative;
  height: 720px;
  border: 5px solid #ffffff;
  filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.05));
  border-radius: 25px;
  overflow: hidden;
}
.contacts__inner-info {
  position: absolute;
  top: 35px;
  left: 35px;
  background: #ebebeb;
  border: 5px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
}
.contacts__info-title {
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  font-family: "Roboto Slab", serif;
}
.contacts__info-line {
  font-family: "Roboto Slab", serif;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  color: #818181;
  & + & {
    margin-top: 10px;
  }
  span {
    font-weight: normal;
    font-size: 20px;
    line-height: 110%;
    color: #818181;
    margin-left: 5px;
    display: inline-flex;
  }
}
.contacts__info-socials {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.contacts__socials--item {
  transition: all 0.3s;
  &:hover {
    opacity: 0.7;
  }
  & + & {
    margin-left: 20px;
  }
  img {
    max-width: 100%;
    width: 30px;
    height: 30px;
  }
}
.body--contacts {
  .footer__inner-map {
    opacity: 0;
  }
}
//end contacts

//start news
.body--news {
  .vacansy__links-btn {
    margin-left: auto;
  }
}
//end news

//start news-page
.news-page {
  margin-top: 25px;
}
.news-page__inner {
  background: #ffffff;
  border: 5px solid #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
}
.news-page__inner-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  font-family: "Roboto Slab", serif;
}
.news-page__inner-descr {
  margin-top: 30px;
}
.news-page__descr-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  color: #818181;
  margin-bottom: 15px;
  font-family: "Roboto Slab", serif;
}
.news-page__descr-text {
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}
//end news-page

//start about
.about {
  margin-top: 25px;
}
.about__inner {
  position: relative;
  background: #fff;
  border: 5px solid #fff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
}
.about__inner-title {
  margin-bottom: 65px;
}
.about__inner-descr {
  font-size: 20px;
  line-height: 130%;
  color: #818181;
  margin-bottom: 25px;
}
//end about

@import "media";
