// font-family: 'Roboto', sans-serif;
// font-family: 'Roboto Slab', serif;

html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  position: relative;
}
body {
  overflow-x: hidden;
  min-width: 320px;
  background: #f9f9f9;
  position: relative;
  font-family: "Roboto", sans-serif;
  &.noscroll {
    overflow-y: hidden;
  }
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}

button:focus,
input:focus {
  outline: transparent;
}

h5 {
  font-size: 20px;
}
a {
  text-decoration: none;
  transition: all 0.3s;
}
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
}
.content {
  flex: 1 0 auto;
}
.footer {
  flex: 0 0 auto;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1450px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
}

.section__title {
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  color: #818181;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 7px;
  border-bottom: 5px solid #818181;
  width: fit-content;
}
