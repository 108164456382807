html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  position: relative;
}

body {
  overflow-x: hidden;
  min-width: 320px;
  background: #f9f9f9;
  position: relative;
  font-family: "Roboto", sans-serif;
}

body.noscroll {
  overflow-y: hidden;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

button:focus,
input:focus {
  outline: transparent;
}

h5 {
  font-size: 20px;
}

a {
  text-decoration: none;
  transition: all 0.3s;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1450px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
}

.section__title {
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  color: #818181;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 7px;
  border-bottom: 5px solid #818181;
  width: fit-content;
}

/* THIS FILE GENERATES AUTOMATICALY FROM src/components/bem-blocks/ FOLDE IF THE RE ARE SCSS FILES */
.header {
  position: relative;
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}

.header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__logo-image {
  max-width: 100%;
  height: 58px;
  width: 58px;
}

.header__nav {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.header__nav-list {
  display: flex;
  align-items: center;
}

.header__nav-item {
  padding: 0 15px;
  margin: 0 15px;
}

.header__nav-link {
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #818181;
  transition: all 0.3s;
}

.header__nav-link:hover {
  opacity: 0.5;
}

.header__buttons {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.header__btn {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #818181;
  padding: 15px 40px;
  margin: 0 17px;
}

.header__btn:hover {
  background-color: #818181;
  color: #fff;
}

.header__btn--mobile {
  display: none;
}

.header__burger {
  display: none;
}

.footer {
  padding: 35px 0 45px;
  background: #818181;
  margin-top: 140px;
}

.footer__inner {
  display: flex;
  justify-content: space-between;
}

.footer__inner-info {
  max-width: 290px;
  margin-right: 15px;
}

.footer__logo {
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.footer__logo-image {
  height: 58px;
  width: 58px;
  max-width: 100%;
}

.footer__info-line {
  font-size: 16px;
  line-height: 110%;
  color: #ffffff;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.footer__info-line span {
  font-weight: 500;
  margin-left: 5px;
}

.footer__inner-map {
  margin-right: 15px;
  height: 150px;
  max-width: 930px;
  width: 100%;
}

.footer__inner-map iframe {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.footer__inner-nav {
  max-width: 110px;
}

.footer__nav-list {
  list-style: none;
}

.footer__nav-item {
  text-align: right;
}

.footer__nav-item + .footer__nav-item {
  margin-top: 20px;
}

.footer__nav-link {
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  transition: all 0.3s;
}

.footer__nav-link:hover {
  opacity: 0.7;
}

.billboard {
  position: relative;
  margin: 75px 0 210px;
}

.billboard__inner {
  width: 100%;
  max-width: 1770px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}

.billboard__inner-info {
  max-width: 855px;
  padding: 100px 70px 100px 160px;
  background-color: #fff;
  border-radius: 50px 0px 0px 50px;
}

.billboard__info-points {
  max-width: 100%;
  height: 10px;
  margin-bottom: 40px;
}

.billboard__info-title {
  font-family: "Roboto Slab", serif;
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 47px;
  line-height: 100%;
  color: #818181;
}

.billboard__info-btn {
  font-weight: bold;
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  color: #ffffff;
  background: #818181;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding: 15px 63px;
  width: fit-content;
}

.billboard__info-btn:hover {
  opacity: 0.7;
}

.billboard__inner-imagebox {
  background: #ebebeb;
  max-width: 885px;
  width: 100%;
  position: relative;
  border-radius: 0 50px 50px 0px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.billboard__inner-imagebox::before {
  content: "";
  position: absolute;
  right: -20px;
  top: -20px;
  height: calc(100% + 40px);
  width: 832px;
  background-image: url(../img/billboard/layout.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.billboard__imagebox-triangles {
  height: 136px;
  width: 136px;
  max-width: 100%;
  top: 30px;
  left: 30px;
  position: absolute;
}

.billboard__imagebox-mainimage {
  height: 552px;
  max-width: 488px;
  margin-top: -70px;
  margin-left: 205px;
}

.billboard__imagebox-textbox {
  position: absolute;
  bottom: -70px;
  left: 15px;
  display: flex;
}

.billboard__textbox-text {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 30px 125px 30px 35px;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #818181;
  margin: 0 15px;
  width: 100%;
  max-width: 332px;
}

.billboard__textbox-text span {
  font-family: "Roboto Slab", serif;
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  color: #818181;
}

.comfort {
  margin: 100px 0;
}

.comfort__title {
  margin-bottom: 10px;
}

.comfort__subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #818181;
}

.comfort__items {
  display: flex;
  justify-content: space-between;
  margin-top: 75px;
}

.comfort__items-item {
  width: 100%;
  max-width: calc(25% - 20px);
  border: 5px solid transparent;
  border-radius: 25px;
  padding: 60px 35px 0px;
  cursor: pointer;
}

.comfort__items-item.active {
  background: #ffffff;
  border: 5px solid #818181;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
}

.comfort__items-item.active:hover {
  opacity: 1;
}

.comfort__item-number {
  font-family: "Roboto Slab", serif;
  background: rgba(235, 235, 235, 0.3);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 50px;
  line-height: 100%;
  color: #818181;
  padding: 10px 23px;
  width: fit-content;
}

.comfort__item-text {
  margin-top: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}

.comfort__contents {
  position: relative;
}

.comfort__contents-content {
  width: 100%;
  max-width: 936px;
  background: linear-gradient(180deg, #f9f9f9 0%, #ffffff 100%);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  opacity: 0;
  visibility: hidden;
  height: 0;
  padding: 0;
}

.comfort__contents-content::before {
  content: "";
  position: absolute;
  left: -20px;
  bottom: -20px;
  width: 100%;
  height: 235px;
  width: 745px;
  background-image: url(../img/comfort/layout.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.comfort__contents-content.active {
  opacity: 1;
  visibility: visible;
  height: auto;
  padding: 78px 120px 70px;
}

.comfort__content-slider {
  width: 100%;
}

.comfort__content-slider .slick-dots {
  bottom: auto;
  top: -40px;
  text-align: left;
}

.comfort__content-slider .slick-dots li {
  margin: 0 2.5px;
}

.comfort__content-slider .slick-dots li button:before {
  width: 10px;
  height: 10px;
  background: #ebebeb;
  content: "";
  border-radius: 50%;
  opacity: 1;
}

.comfort__content-slider .slick-dots .slick-active button:before {
  background: #6d6d6d;
}

.comfort__content-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}

.sphere {
  position: relative;
  margin: 100px 0 140px;
}

.sphere__title {
  margin-bottom: 75px;
}

.sphere__wrapper {
  position: relative;
}

.sphere__wrapper-bg {
  position: absolute;
  width: 100vw;
  height: 689px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: -1;
}

.sphere__inner {
  margin-left: auto;
  margin-right: auto;
}

.sphere__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.sphere__items-item {
  width: 100%;
  max-width: calc(33.333% - 30px);
  margin: 0 15px 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  border: 3px solid #ffffff;
}

.sphere__item-image {
  width: 100%;
  max-width: 100%;
  height: 395px;
  border-radius: 25px 25px 0px 0px;
}

.sphere__item-text {
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  text-align: center;
  color: #818181;
  background-color: #fff;
  padding: 17px 30px 30px;
  width: 100%;
  border-radius: 0px 0px 25px 25px;
}

.vacansy {
  position: relative;
  margin: 140px 0;
}

.vacansy__title {
  margin-bottom: 75px;
}

.vacansy__items {
  display: flex;
  justify-content: space-between;
}

.vacansy__items-item {
  width: 100%;
  max-width: calc(50% - 30px);
  border: 5px solid #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.vacansy__item-top {
  display: flex;
  justify-content: space-between;
}

.vacansy__top-box {
  max-width: 60%;
}

.vacansy__top-title {
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  margin-bottom: 25px;
}

.vacansy__top-id {
  font-size: 20px;
  line-height: 120%;
  color: #818181;
  opacity: 0.4;
}

.vacansy__top-price {
  font-family: "Roboto Slab", serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 110%;
  text-align: right;
  color: #818181;
}

.vacansy__top-price span {
  font-weight: normal;
  color: #818181;
  display: block;
}

.vacansy__item-bot {
  margin-top: 75px;
  display: flex;
  justify-content: space-between;
}

.vacansy__bot-info {
  margin-right: 20px;
}

.vacansy__info-line {
  display: flex;
  align-items: center;
}

.vacansy__info-line + .vacansy__info-line {
  margin-top: 15px;
}

.vacansy__line-icon {
  margin-right: 10px;
  height: 20px;
  width: 20px;
  max-width: 100%;
  display: block;
}

.vacansy__line-text {
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}

.vacansy__bot-links {
  display: flex;
  align-items: center;
}

.vacansy__links-btn {
  font-family: "Roboto Slab", serif;
  margin-left: 25px;
  transition: all 0.3s;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  color: #818181;
}

.vacansy__links-btn:hover {
  opacity: 0.7;
}

.vacansy__links-btn.btn {
  font-family: "Roboto Slab", serif;
  background: #818181;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 24px rgba(41, 215, 121, 0.1);
  border-radius: 25px;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  padding: 10px 18px 9px;
}

.vacansy__links-btn.btn:hover {
  opacity: 0.7;
}

.question {
  margin: 160px 0 20px;
}

.question__inner {
  display: flex;
  justify-content: space-between;
  position: relative;
  background: #ffffff;
  border: 5px solid #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px 155px 45px 35px;
  min-height: 248px;
}

.question__inner::before {
  content: "";
  position: absolute;
  left: -20px;
  top: -20px;
  height: calc(100% + 40px);
  width: 715px;
  background-image: url(../img/question/layout.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.question__inner-form {
  width: 100%;
  max-width: 453px;
}

.question__form-title {
  font-family: "Roboto Slab", serif;
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  padding-bottom: 5px;
  border-bottom: 3px solid #818181;
  width: fit-content;
}

.question__form-text {
  font-size: 20px;
  line-height: 120%;
  color: #818181;
  margin-bottom: 25px;
}

.question__form-inputbox {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  height: 42px;
  background: #ffffff;
  border: 3px solid #818181;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}

.question__form-input {
  width: 100%;
  height: 100%;
  padding: 8px 25px 8px 25px;
  font-size: 16px;
  line-height: 120%;
  border-radius: 25px;
  outline: transparent;
  border: none;
  color: #818181;
}

.question__form-input:placeholder {
  font-size: 16px;
  line-height: 120%;
  color: #818181;
}

.question__form-btn {
  background: #818181;
  border-radius: 25px;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  font-family: "Roboto Slab", serif;
  height: 100%;
  padding: 10px 20px 9px;
  transition: all 0.3s;
  border: none;
  border-radius: 25px;
  margin-right: -1px;
}

.question__form-btn:hover {
  opacity: 0.8;
}

.question__inner-info {
  margin-left: auto;
}

.question__info-title {
  font-family: "Roboto Slab", serif;
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  padding-bottom: 5px;
  border-bottom: 3px solid #818181;
  width: fit-content;
}

.question__info-text {
  font-size: 20px;
  line-height: 120%;
  color: #818181;
  margin-bottom: 25px;
  text-align: center;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.question__info-links {
  display: flex;
  justify-content: center;
}

.question__links-btn {
  padding: 8px 20px 9px;
  cursor: pointer;
  margin: 0 7px;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  font-family: "Roboto Slab", serif;
  color: #ffffff;
  border-radius: 25px;
  border: 3px solid #ffffff;
}

.question__links-btn:hover {
  opacity: 0.7;
}

.question__links-btn img {
  max-width: 100%;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.question__links-btn.viber {
  background: linear-gradient(232.11deg, #59267c 0%, #8f5db7 100%);
  box-shadow: 0px 4px 24px rgba(41, 215, 121, 0.1);
}

.question__links-btn.telegram {
  background: #0088cc;
  box-shadow: 0px 4px 24px rgba(41, 215, 121, 0.1);
}

.catalog {
  margin: 25px 0 0;
}

.catalog .vacansy__items-item {
  max-width: 100%;
  margin-bottom: 30px;
}

.catalog .vacansy__top-title {
  max-width: 410px;
}

.catalog__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.catalog__inner-filters {
  width: 100%;
  max-width: 333px;
  margin-right: 30px;
  background: #ffffff;
  border-radius: 25px;
  padding: 25px;
}

.catalog__filters-line + .catalog__filters-line {
  margin-top: 45px;
}

.catalog__line-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  color: #818181;
  font-family: "Roboto Slab", serif;
  margin-bottom: 12px;
}

.catalog__line-text {
  font-size: 20px;
  line-height: 100%;
  color: #343131;
  cursor: pointer;
  border-top: 1px solid #acacac;
  padding: 12px 0;
  transition: all 0.3s;
  position: relative;
  display: flex;
  align-items: center;
}

.catalog__line-text.active {
  color: #818181;
  font-weight: 500;
  padding-left: 15px;
}

.catalog__line-text.active::before {
  opacity: 1;
  visibility: visible;
}

.catalog__line-text::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #818181;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}

.catalog__inner-content {
  width: 100%;
}

.catalog__content-types {
  display: flex;
  align-items: center;
  margin-left: -11px;
  margin-right: -11px;
}

.catalog__types-type {
  padding: 15px 25px;
  width: calc(16.6666 - 22px);
  margin: 0 11px;
  background: red;
  display: flex;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 25px;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #818181;
  transition: all 0.3s;
}

.catalog__types-type:hover {
  background-color: #818181;
  color: #fff;
}

.catalog__content-items {
  margin-top: 18px;
}

.catalog__content-pagination {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.catalog__pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  margin: 0 10px;
  cursor: pointer;
  color: #818181;
}

.catalog__pagination-item.active {
  color: #ffffff;
  background: #ebebeb;
  cursor: initial;
}

.vacansy-page {
  margin-top: 25px;
}

.vacansy-page__inner {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  border: 5px solid #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
}

.vacansy-page__inner-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.vacansy-page__info-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  font-family: "Roboto Slab", serif;
  max-width: 418px;
}

.vacansy-page__info-title span {
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  color: #818181;
  opacity: 0.8;
  font-family: "Roboto", sans-serif;
  display: block;
  margin-top: 15px;
}

.vacansy-page__info-box {
  background: #ebebeb;
  border: 5px solid #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 30px;
  margin-left: auto;
  margin-right: -35px;
  margin-top: -35px;
}

.vacansy-page__info-price {
  font-family: "Roboto Slab", serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
}

.vacansy-page__info-price span {
  font-weight: 400;
}

.vacansy-page__info-linesbox {
  margin-top: 30px;
}

.vacansy-page__info-line {
  display: flex;
  align-content: center;
}

.vacansy-page__info-line + .vacansy-page__info-line {
  margin-top: 5px;
}

.vacansy-page__line-icon {
  max-width: 100%;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.vacansy-page__line-text {
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}

.vacansy-page__inner-descr {
  width: 100%;
}

.vacansy-page__descr-title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  color: #818181;
  font-family: "Roboto Slab", serif;
}

.vacansy-page__descr-text {
  max-width: 1020px;
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}

.vacansy-page__inner-request {
  margin-top: 30px;
  width: 100%;
}

.vacansy-page__request-title {
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  color: #818181;
  margin-bottom: 25px;
}

.vacansy-page__request-infobox {
  background: #ffffff;
  border: 4px solid #818181;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.vacansy-page__request-col {
  margin: 0 15px;
  max-width: 193px;
}

.vacansy-page__col-title {
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 120%;
  color: #818181;
  opacity: 0.4;
}

.vacansy-page__col-text {
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}

.vacansy-page__btn {
  display: block;
  margin-top: 25px;
  background: #818181;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 24px rgba(41, 215, 121, 0.1);
  border-radius: 25px;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Roboto Slab", serif;
  padding: 15px 63px;
  width: fit-content;
  margin-left: auto;
}

.vacansy-page__btn:hover {
  opacity: 0.7;
}

.contacts {
  margin-top: 25px;
}

.contacts__inner {
  position: relative;
  height: 720px;
  border: 5px solid #ffffff;
  filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.05));
  border-radius: 25px;
  overflow: hidden;
}

.contacts__inner-info {
  position: absolute;
  top: 35px;
  left: 35px;
  background: #ebebeb;
  border: 5px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
}

.contacts__info-title {
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  font-family: "Roboto Slab", serif;
}

.contacts__info-line {
  font-family: "Roboto Slab", serif;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  color: #818181;
}

.contacts__info-line + .contacts__info-line {
  margin-top: 10px;
}

.contacts__info-line span {
  font-weight: normal;
  font-size: 20px;
  line-height: 110%;
  color: #818181;
  margin-left: 5px;
  display: inline-flex;
}

.contacts__info-socials {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.contacts__socials--item {
  transition: all 0.3s;
}

.contacts__socials--item:hover {
  opacity: 0.7;
}

.contacts__socials--item + .contacts__socials--item {
  margin-left: 20px;
}

.contacts__socials--item img {
  max-width: 100%;
  width: 30px;
  height: 30px;
}

.body--contacts .footer__inner-map {
  opacity: 0;
}

.body--news .vacansy__links-btn {
  margin-left: auto;
}

.news-page {
  margin-top: 25px;
}

.news-page__inner {
  background: #ffffff;
  border: 5px solid #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
}

.news-page__inner-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #818181;
  font-family: "Roboto Slab", serif;
}

.news-page__inner-descr {
  margin-top: 30px;
}

.news-page__descr-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  color: #818181;
  margin-bottom: 15px;
  font-family: "Roboto Slab", serif;
}

.news-page__descr-text {
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  color: #818181;
}

.about {
  margin-top: 25px;
}

.about__inner {
  position: relative;
  background: #fff;
  border: 5px solid #fff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 35px;
}

.about__inner-title {
  margin-bottom: 65px;
}

.about__inner-descr {
  font-size: 20px;
  line-height: 130%;
  color: #818181;
  margin-bottom: 25px;
}

@media screen and (max-width: 1449px) {
  .footer__inner-map {
    max-width: 600px;
  }
  .billboard__inner-info {
    padding-left: 60px;
  }
  .vacansy__items {
    flex-wrap: wrap;
  }
  .vacansy__items-item {
    max-width: 100%;
    margin-bottom: 30px;
  }
  .catalog__inner {
    flex-wrap: wrap;
  }
  .catalog__inner-filters {
    max-width: 100%;
    margin-right: 0;
    order: 1;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
  }
  .catalog__filters-line + .catalog__filters-line {
    margin-top: 0;
  }
  .catalog__filters-line {
    width: 100%;
    margin: 0 15px 30px 15px;
    max-width: calc(25% - 30px);
  }
}

@media screen and (max-width: 1249px) {
  .header__burger {
    margin-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 26px;
    margin-right: 20px;
    cursor: pointer;
    z-index: 100;
  }
  .header__burger span {
    height: 4px;
    width: 100%;
    background: #818181;
    border-radius: 3px;
    transition: all 0.3s;
  }
  .header__burger::before {
    content: "";
    position: absolute;
    height: 4px;
    width: 100%;
    background: #818181;
    border-radius: 3px;
    left: 0;
    top: 0;
    transition: all 0.3s;
  }
  .header__burger::after {
    content: "";
    position: absolute;
    height: 4px;
    width: 100%;
    background: #818181;
    border-radius: 3px;
    left: 0;
    bottom: 0;
    transition: all 0.3s;
  }
  .header__burger.active span {
    opacity: 0;
    visibility: hidden;
  }
  .header__burger.active::before {
    transform: rotate(45deg);
    top: 11px;
  }
  .header__burger.active::after {
    transform: rotate(-45deg);
    bottom: 10px;
  }
  .header__nav {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-200%);
    width: 50%;
    height: 100%;
    background: #fff;
    padding: 35px;
    top: 0;
    left: 0;
    margin-left: 0;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
    z-index: 99;
    transition: all 0.3s;
  }
  .header__nav.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  .header__nav-list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .header__nav-item {
    margin: 15px;
    width: 100%;
    text-align: center;
  }
  .header__nav-link {
    text-align: center;
    width: 100%;
    display: block;
  }
  .header__inner {
    justify-content: flex-start;
  }
  .header__buttons {
    display: none;
  }
  .header__btn--mobile {
    display: flex;
    min-width: 165px;
    text-align: center;
    justify-content: center;
  }
  .header__logo {
    margin-right: 20px;
  }
  .header__btn--mobile {
    margin: 0 10px 0;
    padding: 11px 18px;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
  }
  .header__btn--mobile.header__btn--mobile--1 {
    margin-left: auto;
  }
  .header .container {
    padding: 0 10px;
  }
  .header__inner {
    flex-wrap: wrap;
    padding: 15px 0;
  }
  .header__btn--mobile {
    margin-top: 14px;
  }
}

@media screen and (max-width: 1199px) {
  .footer__inner-nav {
    display: none;
  }
  .billboard__inner {
    flex-wrap: wrap;
  }
  .billboard__inner-info {
    order: 1;
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
    max-width: 100vw;
  }
  .billboard__inner-imagebox {
    margin-left: auto;
    margin-right: auto;
    position: initial;
    margin-left: -15px;
    margin-right: -15px;
    max-width: 100vw;
    width: calc(100vw - 10px);
  }
  .billboard__imagebox-textbox {
    left: 0;
    width: 100%;
    max-width: 100%;
    justify-content: center;
  }
  .question__inner {
    flex-wrap: wrap;
    padding: 35px;
  }
  .question__inner-form {
    max-width: 100%;
    margin-bottom: 30px;
    width: 100%;
  }
  .question__inner-info {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
  }
  .question__info-text {
    margin-left: 0;
    max-width: 100%;
    text-align: left;
  }
  .question__info-links {
    justify-content: flex-start;
  }
  .question__form-inputbox {
    max-width: 600px;
  }
  .catalog__content-types {
    flex-wrap: wrap;
  }
  .catalog__types-type {
    width: calc(33.333% - 22px);
    margin-bottom: 22px;
  }
  .vacansy-page__request-infobox {
    flex-wrap: wrap;
    padding-bottom: 5px;
  }
  .vacansy-page__request-col {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 991px) {
  .footer__inner {
    flex-wrap: wrap;
  }
  .footer__inner-info {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    width: 100%;
  }
  .footer__inner-map {
    margin-right: 0;
    max-width: 100%;
    height: 250px;
  }
  .comfort__items {
    flex-wrap: wrap;
  }
  .comfort__items-item {
    max-width: calc(50% - 20px);
    margin-bottom: 20px;
  }
  .comfort__contents {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 20px);
  }
  .comfort__contents-content::before {
    left: -10px;
    bottom: -10px;
  }
  .comfort__contents-content {
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
  }
  .sphere__items-item {
    max-width: calc(50% - 30px);
  }
  .catalog__filters-line {
    max-width: calc(50% - 30px);
  }
  .vacansy-page__info-box {
    margin-top: 0;
    margin-right: 0;
  }
  .vacansy-page__inner-info {
    display: block;
    margin-bottom: 30px;
  }
  .vacansy-page__info-title {
    margin-bottom: 30px;
  }
  .vacansy-page__info-box {
    margin-left: -45px;
    margin-right: -45px;
    max-width: 100vw;
  }
  .vacansy-page__request-title {
    margin-left: 45px;
  }
  .vacansy-page__inner {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .vacansy-page__inner-request {
    margin-left: -45px;
    margin-right: -45px;
    max-width: 100vw;
    width: 100vw;
  }
  .vacansy-page__btn {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) {
  .billboard__imagebox-mainimage {
    margin-left: auto;
    margin-right: auto;
  }
  .vacansy__item-bot {
    flex-wrap: wrap;
  }
  .vacansy__bot-info {
    margin-right: 0;
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
  }
  .vacansy__links-btn {
    margin-left: 0;
    margin-right: 15px;
  }
}

@media screen and (max-width: 649px) {
  .header__logo-image {
    height: 48px;
    width: 48px;
  }
  .header__nav {
    width: 100%;
  }
  .footer__logo {
    float: left;
    margin-right: 34px;
  }
  .footer__logo-image {
    height: 48px;
    width: 48px;
  }
  .billboard__inner-imagebox {
    border-radius: 0 23px 23px 0;
  }
  .billboard__inner-imagebox::before {
    top: -10px;
    width: 100%;
    right: 0px;
    height: 245px;
    background-size: contain;
  }
  .billboard__imagebox-triangles {
    width: 59px;
    height: 59px;
  }
  .billboard__imagebox-mainimage {
    height: 258px;
    max-width: 228px;
    margin-top: -30px;
  }
  .billboard {
    margin-top: 50px;
    margin-bottom: 105px;
  }
  .billboard__inner-info {
    margin-top: 10px;
    padding: 40px 20px 100px;
  }
  .billboard__info-points {
    height: 7px;
    margin-bottom: 15px;
  }
  .billboard__info-title {
    font-size: 28px;
    line-height: 100%;
    margin-bottom: 20px;
  }
  .billboard__textbox-text {
    padding: 15px;
    margin: 0 7px;
    font-size: 15px;
    line-height: 120%;
  }
  .billboard__textbox-text span {
    font-size: 24px;
    line-height: 100%;
  }
  .billboard__info-btn {
    padding: 15px 20px;
    width: 100%;
  }
  .comfort {
    margin: 105px 0 50px;
  }
  .section__title {
    font-size: 24px;
    line-height: 100%;
    border-bottom: 3px solid #818181;
  }
  .comfort__subtitle {
    font-size: 15px;
    line-height: 120%;
  }
  .comfort__items {
    margin-top: 35px;
    margin-right: -10px;
    margin-left: -10px;
  }
  .comfort__items-item {
    padding: 15px;
    max-width: 50%;
  }
  .comfort__items-item.active {
    border: 3px solid #818181;
    padding: 15px;
  }
  .comfort__item-text {
    margin-top: 10px;
    font-size: 14px;
    line-height: 120%;
  }
  .comfort__item-number {
    font-size: 25px;
    line-height: 100%;
    padding: 7px 13px;
  }
  .comfort__contents-content.active {
    padding: 35px 15px 20px 15px;
  }
  .comfort__content-text {
    font-size: 14px;
    line-height: 120%;
  }
  .comfort__contents-content::before {
    height: 124px;
    max-width: 296px;
    background-size: contain;
  }
  .comfort__content-slider .slick-dots {
    top: -40px;
  }
  .comfort__content-slider .slick-dots li {
    margin: 0 1px;
  }
  .comfort__content-slider .slick-dots li button:before {
    width: 7px;
    height: 7px;
  }
  .comfort__contents-content::before {
    bottom: -25px;
  }
  .comfort__contents-content {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }
  .sphere {
    margin: 50px 0;
  }
  .sphere__title {
    margin-bottom: 38px;
  }
  .sphere__item-image {
    height: 220px;
  }
  .sphere__item-text {
    font-size: 16px;
    line-height: 120%;
    padding: 6px 10px 12px;
  }
  .sphere__items-item {
    max-width: calc(50% - 14px);
    margin: 0 7px 14px;
  }
  .sphere__items {
    margin-left: -7px;
    margin-right: -7px;
  }
  .sphere__wrapper-bg {
    height: 470px;
  }
  .vacansy {
    margin: 50px 0;
  }
  .vacansy__title {
    margin-bottom: 35px;
  }
  .footer {
    margin-top: 50px;
  }
  .vacansy__items-item {
    border: 3px solid #fff;
    padding: 15px;
  }
  .vacansy__top-title {
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 5px;
  }
  .vacansy__top-id {
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy__top-price {
    font-size: 16px;
    line-height: 110%;
  }
  .vacansy__item-bot {
    margin-top: 38px;
  }
  .vacansy__info-line + .vacansy__info-line {
    margin-top: 5px;
  }
  .vacansy__line-icon {
    height: 14px;
    width: 14px;
    margin-right: 5px;
  }
  .vacansy__line-text {
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy__links-btn {
    font-size: 10px;
    line-height: 100%;
  }
  .vacansy__links-btn.btn {
    font-size: 10px;
    line-height: 100%;
    padding: 6px 10px 7px;
  }
  .vacansy__bot-links {
    width: 100%;
    justify-content: space-between;
  }
  .vacansy__bot-info {
    margin-bottom: 15px;
  }
  .question__inner {
    padding: 30px 17px;
    margin-right: -15px;
    margin-left: 5px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .question__inner::before {
    left: -13px;
    top: -20px;
    height: calc(100% + 40px);
    max-width: 323px;
    width: 100%;
    background-image: url(../img/question/layout--mobile.png);
    background-size: contain;
  }
  .question__form-title {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 100%;
  }
  .question__form-text {
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 15px;
  }
  .question__form-inputbox {
    height: 35px;
  }
  .question__form-input {
    padding: 8px 25px;
    font-size: 16px;
    line-height: 120%;
    opacity: 0.4;
  }
  .question__form-btn {
    font-size: 16px;
    line-height: 100%;
  }
  .question__info-title {
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 10px;
  }
  .question__info-text {
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 15px;
  }
  .question__links-btn {
    font-size: 16px;
    line-height: 100%;
    margin: 0 15px 0 0;
  }
  .question {
    margin: 60px 0 0 0;
  }
  .about__inner {
    padding: 15px;
  }
  .about__inner-title {
    margin-bottom: 35px;
  }
  .about__inner-descr {
    font-size: 16px;
    line-height: 120%;
  }
  .catalog__filters-line {
    max-width: calc(100%);
    margin: 0 0 20px 0;
  }
  .catalog__inner-filters {
    padding: 15px;
  }
  .catalog__line-title {
    font-size: 16px;
  }
  .catalog__line-text {
    padding: 6px 0;
    font-size: 16px;
  }
  .catalog__content-pagination {
    justify-content: center;
  }
  .catalog__pagination-item {
    height: 20px;
    min-width: 20px;
    font-size: 14px;
  }
  .catalog__types-type {
    padding: 15px 2px;
    font-size: 14px;
    line-height: 100%;
    margin: 0 5px 10px 5px;
    width: calc(33.333% - 10px);
  }
  .contacts {
    margin: 20px 0 0;
  }
  .contacts__inner {
    overflow: visible;
    height: 668px;
  }
  .contacts__inner iframe {
    border-radius: 25px;
  }
  .contacts__inner-info {
    padding: 20px;
    border: 3px solid #ffffff;
    top: -5px;
    left: -10px;
    width: calc(100vw - 20px);
  }
  .contacts__info-title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 100%;
  }
  .contacts__info-line {
    font-size: 14px;
    line-height: 110%;
    align-items: flex-start;
  }
  .contacts__info-line span {
    font-size: 14px;
    line-height: 110%;
  }
  .body--contacts .footer__inner-map {
    display: none;
  }
  .body--contacts .footer {
    padding-bottom: 10px;
  }
  .news-page__inner {
    padding: 15px;
  }
  .news-page__inner-title {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .news-page__inner-descr {
    margin-top: 15px;
  }
  .news-page__descr-title {
    font-size: 15px;
  }
  .news-page__descr-text {
    font-size: 15px;
  }
  .vacansy-page__inner {
    padding: 20px;
  }
  .vacansy-page__inner-info {
    margin-bottom: 20px;
  }
  .vacansy-page__info-title {
    font-size: 16px;
    line-height: 120%;
    max-width: 175px;
    margin-bottom: 20px;
  }
  .vacansy-page__info-title span {
    margin-top: 5px;
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy-page__info-box {
    padding: 25px 30px;
    margin-left: -25px;
    width: calc(100vw - 20px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 3px solid #ffffff;
  }
  .vacansy-page__info-price {
    font-size: 16px;
    line-height: 120%;
  }
  .vacansy-page__info-price span {
    display: block;
  }
  .vacansy-page__info-linesbox {
    margin-top: 0;
    order: -1;
  }
  .vacansy-page__line-icon {
    margin-right: 3px;
    width: 14px;
    height: 14px;
  }
  .vacansy-page__line-text {
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy-page__descr-title {
    font-size: 14px;
    line-height: 110%;
    margin-bottom: 20px;
  }
  .vacansy-page__descr-text {
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy-page__inner-request {
    width: calc(100vw - 20px);
    max-width: calc(100vw - 20px);
    margin-right: -25px;
    margin-left: -25px;
  }
  .vacansy-page__request-title {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 110%;
    margin-left: 25px;
  }
  .vacansy-page__request-infobox {
    padding: 15px;
    border: 3px solid #818181;
  }
  .vacansy-page__btn {
    width: 100%;
  }
  .vacansy-page__col-title {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy-page__col-text {
    font-size: 14px;
    line-height: 120%;
  }
  .vacansy-page__request-col {
    max-width: calc(50% - 20px);
    margin: 10px;
    width: 100%;
  }
  .vacansy-page__inner-descr {
    position: relative;
  }
  .vacansy-page__inner-descr::before {
    content: "";
    position: absolute;
    width: calc(100vw - 40px);
    height: calc(100% + 250px);
    background-color: white;
    z-index: -1;
    top: -30px;
    left: -15px;
  }
}

@media screen and (max-width: 449px) {
  .header__btn--mobile--3 {
    margin-left: auto;
  }
  .sphere__item-image {
    height: 170px;
  }
}

@media screen and (max-width: 389px) {
  .footer__logo-image {
    height: 38px;
    width: 38px;
  }
  .footer__logo {
    margin-right: 15px;
  }
  .footer__info-line {
    font-size: 14px;
    align-items: flex-start;
  }
  .header__btn--mobile {
    min-width: auto;
  }
  .sphere__item-image {
    height: 132px;
  }
  .catalog__types-type {
    width: calc(50% - 10px);
  }
  .contacts__inner-info {
    padding: 15px;
  }
  .contacts__info-line {
    display: block;
  }
  .contacts__info-line span {
    margin-top: 5px;
    display: block;
    margin-left: 0;
  }
}

@media screen and (max-width: 349px) {
  .header__btn--mobile {
    padding: 8px 10px;
  }
}
